import { RecordFlags, RecordPerson } from "./core";

export class Util {
    public static generateRandomId(): string {
        return (Math.random() + 1).toString(36).substring(2);
    }
    public static async fileToBase64(file: Blob): Promise<string> {
        const toBase64Url = (file: Blob) =>
            new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const r: any = reader.result;
                    resolve(r);
                };
                reader.onerror = (error) => reject(error);
            });
        const prefix = ";base64,";
        const base64Url: string = await toBase64Url(file);
        const index = base64Url.indexOf(prefix);
        return base64Url.substring(index + prefix.length);
    }
    public static async imageToBase64(content: any): Promise<string> {
        let result: string;
        if (typeof content === "string") {
            result = content;
        } else if (content instanceof File) {
            result = await Util.fileToBase64(content);
        } else {
            throw new Error("Unkonwn image format: " + result);
        }
        return result;
    }
    public static getIsDatesEqual(date1: any, date2: any): boolean {
        if (date1 instanceof Date === false) {
            return false;
        }
        if (date2 instanceof Date === false) {
            return false;
        }
        let time1 = (date1 as Date).getTime();
        let time2 = (date2 as Date).getTime();
        if (time1 === time2) {
            return true;
        }
        return false;
    }
    public static getHasMedia(person: RecordPerson): boolean {
        if (person.files != null && person.files.length > 0) {
            return true;
        }
        if (person.photos != null && person.photos.length > 0) {
            return true;
        }
        if (!!person.fable) {
            return true;
        }
        return false;
    }
    public static addDays(date: Date, days: number): Date {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }
    public static getHasNegative(flags: RecordFlags) {
        if (flags.isFraud) {
            return true;
        }
        if (flags.isBankFraud) {
            return true;
        }
        if (flags.isMicrofinanceFraud) {
            return true;
        }
        if (flags.isInsuranceFraud) {
            return true;
        }
        if (flags.isMoneyLaundering) {
            return true;
        }
        if (flags.isCargoThief) {
            return true;
        }
        if (flags.isShopThief) {
            return true;
        }
        if (flags.isEmployRisk) {
            return true;
        }
        return false;
    }
    public static generateUUID(): string {
        const randomHex = () =>
            Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        return (
            randomHex() +
            randomHex() +
            "-" +
            randomHex() +
            "-" +
            randomHex() +
            "-" +
            randomHex() +
            "-" +
            randomHex() +
            randomHex() +
            randomHex()
        );
    }
    public static resolveBackgroundColor(user: string): string {
        if (user === "1" || user === "6")
            return "background: linear-gradient(0deg, rgba(129,140,248,1) 35%, rgba(165,180,252,1) 100%);";
        if (user === "2" || user === "7")
            return "background: linear-gradient(0deg, rgba(52,211,153,1) 35%, rgba(110,231,183,1) 100%);";
        if (user === "3" || user === "8")
            return "background: linear-gradient(0deg, rgba(251,146,60,1) 35%, rgba(253,186,116,1) 100%);";
        if (user === "4" || user === "9")
            return "background: linear-gradient(0deg, rgba(244,114,182,1) 35%, rgba(249,168,212,1) 100%);";
        return "background:linear-gradient(0deg, rgba(148,163,184,1) 35%, rgba(203,213,225,1) 100%);";
    }
}
